.renew-instance-container {
  width: 1200px;
  margin: auto;
  .form {
    padding: 30px 0;
    width: 600px;
    margin: auto;
  }
  .form-footer {
    padding: 32px 24px;
    border-top: 1px solid #e6eaea;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    p {
      margin-right: 24px;
      font-size: 16px;
      color: #324558;
      .amount {
        display: inline-block;
        margin-left: 8px;
        line-height: 32px;
        font-size: 32px;
        height: 32px;
        font-weight: 600;
      }
    }

    .btn-submit {
      width: 112px;
      height: 36px;
    }
  }
}

@primary-color: #007AFF;