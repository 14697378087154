.default-table {
    min-height: 430px;
    background: url('../../asserts/icons/img_empty_page_240.svg') center center no-repeat;
    p {
        position: relative;
        top: 280px;
        text-align: center;
        font-size: 14px;
        color: #7F8A94;
    }
    &.default-voucher-table {
        min-height: 240px;
        p {
            top: 180px;
        }
    }
}

.default-table-text {
    margin-top: 100px;
    text-align: center;
    font-size: 14px;
    color: #7F8A94;
}
@primary-color: #007AFF;