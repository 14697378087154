@ThemeColor: #F78326;

.button {
  display: inline-block;
  padding: 0 24px;
  height: 32px;
  font-size: 12px;
  font-weight: 600;

  text-align: center;
  line-height: 32px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  user-select: none;
  vertical-align: -1px;

  &.disabled {
    background-color: #eee !important;
    border-color: #eee !important;
    color: #fff !important;
    cursor: not-allowed;

    &:hover,
    &:link,
    &:active,
    &:focus,
    &:visited {
      color: #eee;
      border-color: #eee;
    }
  }

  &.word-button {
    height: auto;
    line-height: 16px;
    padding: 0;
    color: #0172BB;
    border-color: transparent;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      background-color: inherit !important;
      border: none !important;
      color: gray !important;
      opacity: 0.7;
      cursor: not-allowed;
      text-decoration: none;

      &:hover,
      &:link,
      &:active,
      &:focus,
      &:visited {
        color: gray !important;
      }
    }
  }

  &.normal-button {
    height: 32px;
    line-height: 32px;
    color: #16191F;
    border-radius: 2px;
    border-color: #C0C6CC;

    &:hover,
    &:link,
    &:active,
    &:focus,
    &:visited {
      color: @ThemeColor;
      border-color: @ThemeColor;
    }
  }

  &.dashed-button {
    color: @ThemeColor;
    border-color: @ThemeColor;
    // background: #FFF5EE;
    &:hover,
    &:link,
    &:active,
    &:focus,
    &:visited {
      color: #fff;
      background-color: @ThemeColor !important;
    }
  }

  &.primary-button {
    color: #fff;
    background-color: @ThemeColor;
    border-color: @ThemeColor;
  }
}

@primary-color: #007AFF;