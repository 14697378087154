.pay-container {
    .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }

    .voucher-empty {
        text-align: center;
    }

    .pay-radio-group {
        margin-bottom: 16px;

        .ant-radio-button-wrapper {
            height: 40px;
            line-height: 38px;

            border-width: 2px;
            border-left-width: 0;
            border-color: #E6ECF4;
            &:first-child {
                border-left-width: 2px;
                border-radius:4px 0 0 4px
            }
            &:last-child {
                border-radius:0px 4px 4px 0px
            }

            &:not(:first-child)::before {
                width: 2px;
            }
        }

        .ant-radio-button-wrapper-checked {
            .ant-radio-button {
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                    width: 20px;
                    height: 20px;
                    background: url('./../../../asserts/icons/icon_xuanze_20.svg') no-repeat;
                }
            }

            &:not(.ant-radio-button-wrapper-disabled) {
                &:first-child {
                    border-color: #007AFF;
                }

                &:hover::before {
                    background-color: #007AFF;
                }

                border-color: #007AFF;
            }
        }
    }

   

    .voucher-card {
        width: 270px;
        display: flex;
        flex-direction: column;
        margin-left: 23px;

        &:nth-of-type(n+5) {
            margin-top: 24px;
        }

        &:nth-of-type(4n+1) {
            margin-left: 0px;
        }

        .voucher-img {
            width: 270px;
            height: 110px;
            background: url('./../../../asserts/img_bg_cash_coupon_270.png') no-repeat;
            background-size: 100% 100%;
            padding-top: 10px;
            position: relative;

            .ant-checkbox .ant-checkbox-inner {
                border-color: transparent;
            }



            .voucher-check {
                position: absolute;
                top: 8px;
                left: 10px;
            }

            .voucher-right {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            .voucher-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                b {
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    height: 44px;
                }

                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

        .voucher-text {
            width: 270px;
            height: 58px;
            background: #F5F7FA;
            border-radius: 0px 0px 4px 4px;
            padding: 10px 16px;

            p {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7F8A94;
                line-height: 17px;

                &:last-of-type {
                    margin-top: 4px;
                }
            }
        }
    }
    .remove-bottom{
        .ant-card-body{
            padding-bottom: 0;
        }
    }
    .pay-footer {
        min-height: 90px;
        display: flex;
        align-items: center;
    }

    .pay-btn {
        width: 100%;
        height: 90px;
        background: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #E6ECF4;

        >span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232B3D;
            padding-right: 8px;

        }

        b {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #232B3D;
            padding-right: 24px;
            // transform: translateY(8px);
        }
    }

    .pay-btn-position {
        width: 1200px;
        height: 90px;
        padding: 0 24px;
        // padding-bottom: 24px;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
@primary-color: #007AFF;