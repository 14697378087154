.remove-account-modal {
    .form {
        padding: 24px 48px 36px;
        .form-verify {
            width: 100%;
        }
        .modal-buttons {
            text-align: right;
            .modal-button {
                margin-left: 12px;
            }
        }
        .content {
            font-size: 14px;
            line-height: 24px;
            color: #16191F;
        }
    }
}
@primary-color: #007AFF;