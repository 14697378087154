.document-storage {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .document-layout {
        background-color: #fff;

        .document-sider {
            // position: relative;
            // width: 368px !important;
            // min-width: 368px !important;
            // max-width: 368px !important;

            .document-menus {
                padding: 12px 0 36px;
                height: 100%;
                border-right-color: #E6ECF4;
                top: 60px;

                .ant-menu-item {
                    margin: 0;
                    padding: 0 24px;

                    a {
                        color: #324558;
                    }

                    span {
                        color: #324558;
                    }

                    &.ant-menu-item-selected {
                        position: relative;
                        background-color: transparent;

                        a {
                            color: #324558;
                        }

                        span {
                            color: #324558;
                        }

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 4px;
                            height: 100%;
                            background-color: #0FB187;
                        }
                    }

                    &.ant-menu-item-active,
                    &:hover,
                    &:focus {
                        background-color: transparent;

                        a {
                            color: #324558;
                        }

                        span {
                            color: #324558;
                        }
                    }

                    &.unclickable {
                        cursor: default;
                    }
                }
            }
        }

        .document-content {
            padding: 48px 24px;
            padding-right: 0px;
            height: 74vh;

            .document-content-box {
                height: 100%;
                padding-right: 20px;
                overflow-x: hidden;
                &::-webkit-scrollbar {
                    /*高宽分别对应横竖滚动条的尺寸*/
                    width: 5px;
                }

                /*滚动条里面滑块*/
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: #c5c6c7;
                }
                img{
                    width: 100%;
                }
                code {
                    display: block;
                    margin: 10px 0;
                    padding: 20px;
                    background-color: #F5F7FA;
                  }
            }

            h2 {
                margin-bottom: 12px;
                font-size: 18px;
                font-weight: 600;
                color: #324558;
                line-height: 16px;

                &:not(:first-child) {
                    margin-top: 12px;
                }
            }

            h3 {
                margin-top: 24px;
                font-size: 14px;
                color: #324558;
                font-weight: 600;

                line-height: 28px;
            }

            p {
                font-size: 14px;
                color: #324558;
                line-height: 28px;
            }

            b {
                font-size: 14px;
                display: block;
                font-weight: 600;
                margin-top: 10px;
            }

            strong {
                font-weight: 600;
            }
        }
    }
}
@primary-color: #007AFF;