.modify-type-container {
  width: 1200px;
  margin: auto;
  .form {
    padding: 30px 0;
    width: 600px;
    margin: auto;
    .system-type {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .modify-icon {
        margin: 0 24px;
      }
      .new-type {
        margin-left: 2px;
        color: #0fb187;
      }
    }
  }
  .form-footer {
    padding: 32px 24px;
    border-top: 1px solid #e6eaea;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    p {
      margin-right: 24px;
      font-size: 16px;
      color: #324558;
      .amount {
        display: inline-block;
        margin-left: 8px;
        line-height: 32px;
        font-size: 32px;
        height: 32px;
        font-weight: 600;
      }
    }

    .btn-submit {
      width: 112px;
      height: 36px;
    }
  }
  .ant-input-group {
    .ant-input {
      border-right-width: 0;
      &:hover,
      &:focus,
      &:active {
        border-right-width: 0 !important;
      }
    }
  }
  .ant-form-item-has-error {
    .ant-input-group-addon {
      border-color: rgb(255, 77, 79);
    }
  }
  .ant-input-affix-wrapper {
    border-right-width: 0;
  }
  .ant-input-group-addon {
    border-left-width: 0;
    border-color: #e6ecf4;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
    .ant-btn {
      width: 116px;
      height: 28px;
      background: #e6ecf4;
      border-radius: 4px;
      padding: 0;
      span {
        font-size: 12px;
        color: #007aff;
      }
    }
  }
}

@primary-color: #007AFF;