.invoicelist-container {
    .invoicelist-footer {
        text-align: right;
        margin-top: 28px;
        >span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #324558;
            padding-right: 24px;
        }
    }
}
@primary-color: #007AFF;