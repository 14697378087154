.instance-detail-container {
  width: 1200px;
  margin: auto;

  .ant-card-body {
    padding: 0;
  }

  .detail-sider {
    width: 237px;
    background-color: #fff;
    border-right: 1px solid #e6ecf4;
    border-radius: 4px 0 0 4px;
    .sider-nav {
      padding-top: 40px;
      li {
        position: relative;
        margin-bottom: 28px;
        padding-left: 24px;
        height: 40px;
        font-size: 14px;
        color: #324558;
        line-height: 40px;
        cursor: pointer;

        &.selected {
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #0fb187;
          }
        }
      }
    }
  }
  .detail-content {
    background-color: #fff;



    .mount-step {
      padding: 48px;
      color: #324558;
      p {
        line-height: 28px;
      }
      .title {
        margin-bottom: 12px;
        font-size: 14px;
        color: #324558;
        font-weight: 600;
        line-height: 14px;
      }
      .content-primary {
        margin-top: 12px;
      }
      .content-secondary {
        line-height: 28px;
        padding-left: 24px;
      }
      .content-three-level {
        padding-left: 48px;
      }
      i {
        text-decoration: underline;
      }
      code {
        display: block;
        margin: 10px 0;
        padding: 20px;
        background-color: #F5F7FA;
      }
      .copy-button {
        line-height: 16px;
        .anticon {
          position: relative;
          top: -1px;
          vertical-align: middle;
        }
      }
    }

    .list {
      padding: 48px 48px 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #E6ECF4;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 14px;

        &:nth-child(2n + 1) {
          width: 300px;
        }

        &:nth-child(2n) {
          width: 550px;
        }

        .label {
          display: block;
          margin-right: 8px;
          width: 100px;
          text-align: right;
          font-size: 14px;
          color: #7F8A94;
        }

        .progress {
          width: 400px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ant-progress-text {
            font-size: 0;
          }
          .ant-progress-inner {
            background-color: rgba(1, 113, 187, 0.2);
          }
        }

        .value {
          font-size: 14px;
          color: #324558;
          white-space: nowrap;
        }

        .copy-button {
          color: #0172BB;
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
    .radio-group {
      margin: 24px;
      // padding: 0 50px;

      .radio-button {
        width: 60px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background-color: #E6ECF4;
        border-color: #E6ECF4;
        &:nth-child(1) {
          border-radius: 4px 0 0 4px;
        }
        &:nth-child(2) {
          border-radius: 0 4px 4px 0;
        }
        &.ant-radio-button-wrapper-checked {
          border-color: #333;
          color: #fff;
          background-color: #333;
        }
      }
    }
    .chart-tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .title {
        padding-left: 50px;
        font-size: 14px;
        color: #324558;
      }
      .tools {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 50px;
        .chart-select {
          margin-right: 22px;
          width: 110px;
          .ant-select-selection-item {
            color: #324558;
          }
          .ant-select-item-option-content {
            color: #324558;
          }
        }
        .label {
          margin: 0 10px;
          font-size: 14px;
          color: #324558;
        }
        .ant-picker-input input {
          color: #324558;
        }
        .ant-select-selector,
        .ant-picker {
          height: 36px !important;
          .ant-select-selection-item {
            line-height: 34px;
          }
        }
      }
    }
    .charts {
      .chart-box {
        margin-bottom: 50px;
        &:not(:last-child) {
          border-bottom: 1px solid #e6eaea;
        }
        .chart {
          padding: 0 50px 50px;
          width: 100%;
          height: 300px !important;
        }
      }
    }
  }
}

// .instance-detail {
//   box-shadow: 0px 2px 3px 0px #B7BFC1;

//   .page-header {
//     padding-left: 0;
//     padding-right: 0;
//     height: 70px;
//   }

//   .tabs {
//     background-color: #fff;

//     table {
//       margin: 14px 95px;

//       .title {
//         width: 160px;
//         vertical-align: top;
//         color: #324558;
//       }

//       td {
//         line-height: 32px;
//         padding-bottom: 14px;
//       }
//     }

    // .list {
    //   padding-bottom: 24px;
    //   margin: 0 95px;

    //   .item {
    //     display: flex;
    //     justify-content: left;
    //     align-items: top;
    //     line-height: 32px;
    //     margin-bottom: 14px;

    //     .label {
    //       display: block;
    //       width: 120px;
    //       font-size: 14px;
    //       line-height: 32px;
    //       color: #324558;
    //     }

    //     .progress {
    //       width: 300px;
    //       display: flex;
    //       justify-content: flex-start;
    //       align-items: center;
    //       .ant-progress-text {
    //         font-size: 0;
    //       }
    //       .ant-progress-inner {
    //         background-color: rgba(1, 113, 187, 0.2);
    //       }
    //     }

    //     .value {
    //       font-size: 14px;
    //       line-height: 32px;
    //       color: #16191f;
    //       white-space: nowrap;
    //     }

    //     .copy-button {
    //       color: #0172BB;
    //     }

    //     &::after {
    //       content: "";
    //       display: block;
    //       clear: both;
    //     }
    //   }
    // }
    // .radio-group {
    //   margin-bottom: 24px;
    //   padding: 0 50px;

    //   .radio-button {
    //     width: 88px;
    //     height: 36px;
    //     text-align: center;
    //     line-height: 36px;
    //   }
    // }
  // }
//   .mount-step {
//     .content-primary {
//       margin-top: 12px;
//     }
//     .content-secondary {
//       padding-left: 24px;
//     }
//     .content-three-level {
//       padding-left: 48px;
//     }
//     i {
//       text-decoration: underline;
//     }
//     code {
//       display: block;
//       padding: 12px;
//       background-color: #eeeeee;
//     }
//   }
//   .chart-tools {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 20px;
//     .title {
//       padding-left: 50px;
//       font-size: 14px;
//       color: #324558;
//     }
//     .tools {
//       padding-right: 50px;
//       .chart-select {
//         margin-right: 22px;
//         width: 110px;
//         .ant-select-selection-item {
//           color: #324558;
//         }
//         .ant-select-item-option-content {
//           color: #324558;
//         }
//       }
//       .label {
//         margin: 0 10px;
//         font-size: 14px;
//         color: #324558;
//       }
//       .ant-picker-input input {
//         color: #324558;
//       }
//     }
//   }
//   .charts {
//     .chart-box {
//       margin-bottom: 50px;
//       &:not(:last-child) {
//         border-bottom: 1px solid #E6EAEA;
//       }
//       .chart {
//         padding: 0 50px 50px;
//         width: 100%;
//         height: 300px !important;
//       }
//     }
//   }
// }

@primary-color: #007AFF;