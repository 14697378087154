.invoiceTitle {
    .ant-select:not(.ant-pagination-options-size-changer) {
        .ant-select-selector {
            height: 36px !important;

            .ant-select-selection-item {
                line-height: 36px;
            }
        }

    }
    .ant-input{
        height: 36px;
    }
}
@primary-color: #007AFF;