.invoice-two-container {
    .invoice-two-box{
        width: 728px;
        margin: 0 auto;
    }
    .two-title {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #324558;
        padding-bottom: 10px;
        margin-bottom: 16px;
        border-bottom: 1px dashed #E6ECF4;
    }

    .two-box {

        &:first-of-type {
            padding-bottom: 24px;
        }

        .invoice-title {
            .ant-radio-wrapper {
                display: flex;
                align-items: center;
                height: 20px;

                .radio-wrapper-text {
                    display: flex;
                    align-items: center;

                    >span {
                        padding-right: 4px;
                    }
                }
            }
        }

        .two-add-address {
            display: block;
            margin-top: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;

            .ant-btn {
                padding-left: 0;
            }

            .ant-btn[disabled] {
                background-color: transparent;
                border-color: transparent;
            }
        }
        .ant-col-5{
            max-width: 19%;
        }
        .ant-col-offset-1{
            margin-left: 2%;
        }
    }

    .invice-two-footer {
        width: 100%;
        padding: 28px 24px 24px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E6ECF4;
        border-top: none;
        text-align: right;
    }
}
@primary-color: #007AFF;