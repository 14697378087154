.voucher-container {
  width: 1200px;
  margin: auto;
  .card-header {
    .tools {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .billing-cycle {
        margin-right: 8px;
      }
    }
  }
  .ant-card-body {
    padding: 24px;
  }
  .vouchers {
    width: 1152px;
    margin: auto;
    .title {
      margin-bottom: 16px;
      font-size: 16px;
      color: #324558;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 24px;
      .voucher {
        margin-bottom: 24px;
        width: 270px;
        height: 200px;
        cursor: pointer;

        &:not(:nth-child(4n)) {
          margin-right: 24px;
        }

        .voucher-header {
          padding-top: 6px;
          position: relative;
          height: 110px;
          text-align: center;
          background: url('../../../asserts/icons/img_bg_cash_coupon_360.svg') center center / cover no-repeat;
          .available {
            font-size: 32px;
            color: #fff;
          }
          .available-label {
            font-size: 14px;
            color: #fff;
          }
          .total {
            font-size: 14px;
            color: #fff;
          }
          .voucher-checkbox {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            &.checked {
              border: 4px solid #007aff;
              background-color: transparent;
            }
          }
          .status {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 12px;
            color: #fff;
          }
        }
        .voucher-content {
          padding: 10px 16px;
          background-color: #F5F7FA;
          p {
            color: #7F8A94;
            font-size: 12px;
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #007AFF;