@import (reference) "../../../styles/flex.less";

.activation-container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 60px;
    min-width: 1200px;
    background-color: #fff;
  }
  .success-box {
    margin: 60px auto;
    padding-top: 64px;
    width: 440px;
    height: 390px;
    background-color: #fff;
    text-align: center;
    .tabletsDown(margin-top, 0);
    .tabletsDown(width, 100%);
    .success-icon {
      margin: 0 auto 32px;
      width: 80px;
      height: 80px;
      background: url("../../../asserts/icons/icon_successful_80.svg") center
        center no-repeat;
    }
    .success-content {
      margin-bottom: 12px;
      font-size: 16px;
      color: #324558;
    }
    .activate-content {
      margin-bottom: 64px;
      font-size: 14;
      color: #324558;
    }
    .success-btn {
      width: 320px;
    }
  }
}

// .activation-container {
//   margin: auto;
//   margin-top: 60px;
//   width: 530px;
//   padding: 110px 50px 120px;
//   background: #ffffff;
//   box-shadow: 0px 2px 3px 0px #b7bfc1;
//   .success-icon {
//     position: relative;
//     margin: auto;
//     margin-bottom: 30px;
//     width: 110px;
//     height: 110px;
//     border-radius: 50%;
//     background-color: #fff5ee;
//     border: 3px solid rgba(247, 131, 38, 0.6);
//     user-select: none;
//     img {
//       position: absolute;
//       top: 0;
//       left: 0;
//       bottom: 0;
//       right: 0;
//       margin: auto;
//       width: 60px;
//     }
//   }
//   .success-content {
//     margin-bottom: 12px;
//     font-size: 22px;
//     color: #3c4242;
//     text-align: center;
//     line-height: 30px;
//   }
//   .success-activation {
//     margin-bottom: 55px;
//     line-height: 20px;
//     color: #323636;
//     font-size: 14px;
//     text-align: center;
//   }
//   .link-button {
//       display: block;
//     width: 430px;
//     height: 42px;
//     color: #fff;
//     background: #f78326;
//     border-radius: 5px;
//     border-color: #f78326;
//     text-align: center;
//     font-size: 18px;
//     line-height: 42px;
//     span {
//       font-size: 18px;
//     }
//   }
// }

@primary-color: #007AFF;