.modify-config-modal {
    .annotation-icon {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('../../../asserts/icons/icon_annotation_normal_16.svg') center center / 100% 100% no-repeat;
        &:hover {
          background-image: url('../../../asserts/icons/icon_annotation_highlight_16.svg');
        }
      }
}
@primary-color: #007AFF;