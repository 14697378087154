.dosage-container {
    .load-button {
        display: flex;
        align-items: center;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #324558;
        padding: 4px 10px;
    }

    .dosage-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
        font-weight: 400;
        color: #324558;

        .dosage-date-picker {
            width: 180px;
            height: 36px;
            border-radius: 4px;
            border: 1px solid #E6ECF4;
        }

        .dosage-select {
            .ant-select-selector {
                width: 160px;
                height: 36px !important;
                border-radius: 4px;
                border: 1px solid #E6ECF4;
                .ant-select-selection-item{
                    line-height: 34px;
                }
            }
        }
    }
}
@primary-color: #007AFF;