@import (reference) "../../../styles/flex.less";

.sign-up-container {
  padding-top: 36px;
  .tabletsDown(padding-top, 30px);
  .title {
    padding: 0 40px;
    margin-bottom: 24px;
    color: #324558;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }
  .sign-up-form {
    padding: 0 40px;
    .tabletsDown(padding, 0 30px)
  }
  .verify-item {
    .ant-input-affix-wrapper {
      border-right-width: 0;
    }
    .ant-input-group-addon {
      border-left-width: 0;
      border-color: #e6ecf4;
      background-color: #fff;
      border-radius: 0 4px 4px 0;
      .ant-btn {
        width: 116px;
        height: 28px;
        background: #e6ecf4;
        border-radius: 4px;
        padding: 0;
        span {
          font-size: 12px;
          color: #007aff;
        }
      }
    }
  }
  .btn-register {
    .tabletsDown(display, none)
  }
  .sign-in-tools {
    margin-top: 12px;
    height: 48px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed #E6ECF4;
    .tabletsDown(display, none);
  }
  .ant-form-item-has-error {
    .ant-input-group-addon {
      border-color: rgb(255, 77, 79);
    }
  }
  .mobile-btn-box {
    display: none;
    margin: 28px auto;
    padding: 0 30px;
    .tabletsDown(display, block);
    .btn-register-mobile {
      width: 100%;
      height: 40px;
    }
    .btn-login-mobile {
      display: block;
      margin-top: 30px;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      border: 1px solid #0FB187;
      font-size: 16px;
      color: #0FB187;
      line-height: 38px;
      text-align: center;
    }
  }
  .ant-input-group {
    &:hover {
      .ant-input-group-addon {
        border: 1px solid #007AFF;
        border-left-width: 0;
        &:hover {
          border-color: #e6ecf4;
        }
      }
    }
  }
}

@primary-color: #007AFF;