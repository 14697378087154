.invoice-container {
    .invoice-title {
        display: flex;
        width: 100%;
        padding: 24px;
        min-height: 185px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E6ECF4;
        margin-bottom: 20px;

        .ant-spin-nested-loading {
            &:first-of-type{
                width: 43.4%;
            }
            &:nth-of-type(2){
                flex: 1;
            }
            &:last-of-type{
                flex: 1;
            }
        }

        .invoice-apply {
            // width: 43.4%;
            width: 100%;
            height: 137px;
            border-top: 1px solid #E6ECF4;
            border-bottom: 1px solid #E6ECF4;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 24px;

            .apply-info {
                padding: 24px 24px 24px 18px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                p {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #7F8A94;
                    line-height: 20px;

                    span {
                        color: #324558;
                    }
                }

                b {
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    height: 33px;
                    line-height: 33px;
                    margin:8px 0;
                    color: #324558;
                }
            }

            &::after {
                content: '';
                height: calc(100% + 2px);
                display: block;
                position: absolute;
                top: -1px;
                left: 0;
                width: 6px;
                background: #333333;

            }
        }

        .invoice-info,
        .invoice-address {
            height: 137px;
            padding: 16px;
            border: 1px solid #E6ECF4;
            flex: 1;

            p {
                padding-bottom: 8px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #324558;

                &:first-of-type {
                    display: flex;
                    justify-content: space-between;

                    b {
                        font-weight: 600;
                    }

                    span {
                        color: #007AFF;
                        cursor: pointer;
                    }
                }

                span {
                    padding-left: 8px;
                }
            }

            .address-max {
                max-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }

        .invoice-address {
            border-left: 0;
        }
    }
}
@primary-color: #007AFF;