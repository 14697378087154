body {
  font-family: "PingFang SC", "Microsoft Yahei", sans-serif, Arial;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #007AFF;
  &:hover {
    color: #007ade;
  }
}

* {
  box-sizing: border-box;
  font-size: 14px;
}

input {
  &::-webkit-input-placeholder {
    font-size: 14px !important;
    color: #7F8A94 !important;
  }

  &::-moz-placeholder {
    font-size: 14px !important;
    color: #7F8A94 !important;
  }

  &:-ms-input-placeholder {
    font-size: 14px !important;
    color: #7F8A94 !important;
  }
}

strong {
  font-weight: 600;

}
.bill-container,
.bill-detail-container,
.dosage-container,
.invoice-container,
.invoice-one-container,
.invoice-two-container,
.pay-container {
  width: 1200px;
  margin: auto;
}