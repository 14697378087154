@import (reference) "../../../styles/flex.less";

.experience-submit-container {
  width: 1200px;
  .tabletsDown(width, 100%);
  .tabletsDown(font-size, 15px);
  margin: auto;

  .caption {
    .tabletsDown(font-size, 15px);
  }
  .card-title {
    margin-left: 270px;
    white-space: pre-wrap;
    .tabletsDown(font-size, 15px);
    .tabletsDown(font-weight, 600);
    .tabletsDown(margin-left, 0px);
  }

  .form {
    padding: 30px 0;
    margin: auto;
    width: 600px;
    .tabletsDown(width, 100%);
    .tabletsDown(padding, 30px 30px);
    .ant-form-item {
      label {
        .tabletsDown(font-size, 15px);
        .tabletsDown(font-weight, 400);
        span{
          .tabletsDown(font-size, 15px);
        }
      }
    }
  }
  .ant-upload-list {
    margin-top: 30px;
  }
  .upload-tip {
    margin-top: 60px;
    margin-bottom: -66px;
    font-size: 14px;
    color: #7F8A94;
    line-height: 24px;
  }
  .form-footer {
    text-align: center;
    padding: 28px 65px;
    border-top: 1px solid #E6ECF4;
    button {
      span{
        .tabletsDown(font-size, 16px);
      }
    }
  }
}

@primary-color: #007AFF;