.tickets-container {
  width: 1200px;
  margin: auto;
  .tickets {
    padding: 20px;
    background-color: #fff;
    .btn-create {
      text-align: center;
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
      .anticon {
        position: relative;
        top: -3px;
      }
    }
    .tickets-tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        color: #324558;
        font-size: 14px;
      }
    }
    .table {
      border-radius: 4px;
      margin-top: 12px;
    }
  }
}
@primary-color: #007AFF;