.bill-detail-container {
    .bill-info-collapse {
        padding: 20px 0;
        border-bottom: 2px dashed #E6ECF4;
        border-top: 2px dashed #E6ECF4;

        //Collapse 头部样式重写
        .ant-collapse-header {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #324558;
            height: 20px;
            padding: 0;

            // 自定义icon 设置对其方式
            >div:first-of-type {
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                .ant-collapse-arrow {
                    margin-right: 8px;
                    transition: transform .24s;
                }
            }

            // 自定义的header
            .bill-info-header {
                width: 100%;
                display: flex;

                span {
                    display: inline-block;
                    width: 33.3%;
                    text-align: left;

                    &:last-of-type {
                        display: flex;
                        justify-content: space-between;

                        b {
                            font-weight: 400;
                            color: #324558;
                        }
                    }
                }
            }
        }

        // Collapse 容器 修改默认padding
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding: 8px 20px;
                padding-right: 0;
                padding-bottom: 0;
            }
        }

        // 折叠Panel
        .bill-info-panel:not(span) {
            background: #F9FBFF;
            border-radius: 4px;
            padding: 8px;
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }

        // 文件系统列表项ul
        .bill-info-ul {
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7F8A94;
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }

            li {
                &:first-of-type {
                    padding-left: 20px;
                }

                width: 33.3%;
            }
        }
    }
}
@primary-color: #007AFF;