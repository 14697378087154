.activate-voucher-modal {
    .ant-modal-body {
        padding-bottom: 0;
    }
    .ant-modal-footer {
        .ant-btn:nth-child(1) {
            display: none;
        }
        .ant-btn:nth-child(2) {
            width: 84px;
        }
    }
}
@primary-color: #007AFF;