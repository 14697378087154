.document-container {
  width: 1200px;
  margin: auto;
  .page-header {
    padding-bottom: 0;
    .description {
      margin-bottom: 24px;
    }
  }
  .document-layout {
    background-color: #fff;
    .document-sider {
      position: relative;
      width: 368px !important;
      min-width: 368px !important;
      max-width: 368px !important;
      .document-menus {
        padding: 12px 0 36px;
        height: 100%;
        border-right-color: #E6ECF4;
        top: 60px;
          .ant-menu-item {
            margin: 0;
            padding: 0 24px;
            a {
              color: #324558;
            }
            span {
              color: #324558;
            }
            &.ant-menu-item-selected {
              position: relative;
              background-color: transparent;
              a {
                color: #324558;
              }
              span {
                color: #324558;
              }
              &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: #0FB187;
              }
            }
            &.ant-menu-item-active,
            &:hover,
            &:focus {
              background-color: transparent;
              a {
                color: #324558;
              }
              span {
                color: #324558;
              }
            }
            &.unclickable {
              cursor: default;
            }
          }
      }
    }
    .document-content {
      padding: 48px 24px;
      table {
        margin: 12px 0;
        width: 100%;
        th {
          padding-left: 24px;
          height: 36px;
          font-weight: 400;
        }
        td {
          padding-left: 24px;
          height: 46px;
        }
        td {
          border-bottom: 1px solid #E6ECF4;
          border-left: 1px solid #E6ECF4;
          &:last-child {
            border-right: 1px solid #E6ECF4;
          }
        }
        th {
          background-color: #F2F7FF;
          border-top: 1px solid #E6ECF4;
          border-left: 1px solid #E6ECF4;
          border-bottom: 1px solid #E6ECF4;
          &:last-child {
            border-right: 1px solid #E6ECF4;
          }
        }
      }
      h2 {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 600;

        color: #324558;
        line-height: 16px;
      }
      h3 {
        margin-top: 24px;
        font-size: 14px;
        color: #324558;
        font-weight: 600;

        line-height: 28px;
      }
      p {
        font-size: 14px;
        color: #324558;
        line-height: 28px;
      }
      strong {
        font-weight: 600;

      }
      .anchor {
        position: relative;
        top: -50px;
        min-height: 24px;
      }
    }
  }
}

@primary-color: #007AFF;