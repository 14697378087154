.setting-container {
  width: 1200px;
  margin: auto;

  .setting-sider {
    width: 237px !important;
    min-width: 237px !important;
    max-width: 237px !important;
    background-color: #fff;
    border-right: 1px solid #E6ECF4;
    border-radius: 4px 0 0 4px;
    .sider-nav {
      padding-top: 40px;
      li {
        position: relative;
        margin-bottom: 28px;
        padding-left: 24px;
        height: 40px;
        font-size: 14px;
        color: #324558;
        line-height: 40px;
        cursor: pointer;

        &.selected {
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #0FB187;
          }
        }
      }
    }
  }
  
  .setting-content {
    padding-left: 64px;
    padding-top: 50px;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
  }
}

@primary-color: #007AFF;