.message-container {
  width: 1200px;
  margin: auto;

  .message-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #F2F6FC;
    border-radius: 4px 4px 0 0;
    li {
      position: relative;
      padding: 0 32px;
      height: 100%;
      font-size: 14px;
      color: #324558;
      text-align: center;
      line-height: 40px;
      border-right: 1px solid #E6ECF4;
      cursor: pointer;
      &.active {
        &::after {
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          content: "";
          height: 4px;
          width: 100%;
          background-color: #324558;
        }
      }
    }
  }
  .message-box {
    background-color: #fff;
    .message-list {
      padding: 0 12px 12px;
      width: 515px;
      height: 100%;
      display: inline-block;
      vertical-align: top;
      border-right: 1px solid #E6ECF4;
      overflow-y: auto;
      .tools {
        padding: 24px 0 24px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .total {
          font-size: 14px;
          color: #7F8A94;
        }
      }
      .table {
        height: calc(100% - 88px);
        overflow-y: auto;
      }
      .ant-table-row {
        display: flex;
        flex-direction: row-reverse;
        .ant-table-cell {
          &:nth-child(1) {
            height: auto;
            .ant-checkbox-wrapper {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &:nth-child(2) {
            padding: 8px 0;
            width: 100%;
            height: auto;
          }
        }
        &.row-selected {
          background-color: #F2F7FF;
        }
      }
      
      .message-item {
        position: relative;
        height: 100%;
        display: block;
        padding: 0 16px;
        cursor: pointer;
        .title {
          margin-bottom: 4px;
          font-size: 14px;
          color: #324558;
          line-height: 24px;
        }
        .time {
          font-size: 12px;
          color: #7F8A94;
        }
        .message-hint {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background-color: #D0021B;
          border-radius: 50%;
        }
      }
    }
    .message-detail {
      padding: 76px 16px 12px;
      width: 685px;
      height: 100%;
      display: inline-block;
      vertical-align: top;
      overflow-y: auto;
      .title {
        font-size: 16px;
        color: #324558; 
        line-height: 16px;
        font-weight: 600;
      }
      .time {
        margin-bottom: 24px;
        color: #7F8A94;
        font-size: 12px;
      }
      .article {
        height: calc(100% - 119px);
        overflow-y: auto;
        p {
          font-size: 14px;
          color: #324558;
        }
        h1 {
          font-size: 16px;
          color: #324558;
        }
        table {
          border-color: #E6ECF4;
          td {
            border-color: #E6ECF4;
          }
        }
      }
    }
  }
}

@primary-color: #007AFF;