@import (reference) "../../styles/flex.less";

.common-layout {
  .header {
    height: 60px;
    width: 100%;
    background-color: #333;
    line-height: 60px;
    position: fixed;
    top: 0;
    z-index: 100;
    user-select: none;
    .tabletsDown(padding-left, 20px);

    .container {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        width: 212px;
      }
      .github {
        margin-left: 40px;
        .tabletsDown(margin-left, 20px);
        font-size: 14px;
        color: #fff;
        cursor: pointer;

        .menu_new_highlight {
          position: absolute;
          width: 31px;
          height: 14px;
          top: 19px;
          margin-left: 1px;
        }
      }
      .nav {
        margin-left: auto;
        .tabletsDown(display, none);
        .links {
          float: left;
          .link {
            float: left;
            height: 100%;
            cursor: pointer;
            font-size: 14px;
            color: #fff;

            &.link-personal {
              .link-dropdown {
                width: 280px;
              }
            }

            .link-content {
              display: block;
              padding: 0 20px;
            }

            &:hover .link-content {
              background-color: #3D3D3D;
            }

            &.link-personal:hover {
              background-color: transparent;
            }

            .link-dropdown {
              position: relative;
              // top: 20px;
              padding: 4px 8px;
              width: 172px;
              background-color: #fff;
              box-shadow: 0 0 6px 0 rgba(0,78,255,0.08);

              .ant-menu {
                border-right-width: 0;
                cursor: pointer;
              }

              .ant-menu-item {
                padding: 0 8px;
                height: 32px;
                line-height: 32px;
                border-radius: 4px;
                .anticon {
                  position: relative;
                  top: -3px;
                  margin-right: 8px;
                }
              }

              .ant-menu-item-active {
                color: #324558;
                background-color: #F2F7FF;
              }

              .personal-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 12px;
                border-bottom: 1px solid #E6ECF4;
                cursor: default;

                .personal-info {
                  margin-left: 8px;
                  .email {
                    font-size: 14px;
                    color: #324558;
                  }
                  .phone {
                    font-size: 12px;
                    color: #7F8A94;
                  }
                }
              }
              .item-personal {
                height: 32px;
                padding-left: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &:hover {
                  background-color: #F2F7FF;
                }
              }
              .ant-spin-container {
                padding: 8px;
              }
            }

            .link-dropdown-message {
              position: relative;
              top: 20px;
              width: 360px;
              min-height: 240px;
              box-shadow: 0 0 6px 0 rgba(0, 78, 255, 0.08);
              background-color: #fff;
              .link-list-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;
                height: 52px;
                border-bottom: 1px solid #E6ECF4;
              }
              .empty-container {
                height: 180px;
                text-align: center;
                line-height: 180px;
                font-size: 14px;
                color: #7F8A94;
              }
            }
            &.link-message {
              padding: 0 20px;
              .content-message {
                position: relative;
                top: -2px;
              }
              .ant-spin-container {
                padding: 8px;
              }
              .link-message-item {
                height: auto;
                line-height: auto;
                >span{
                  display: block;
                  padding: 8px;
                  .title {
                    font-size: 12px;
                    color: #324558;
                    letter-spacing: 0;
                    line-height: 20px;
                  }
                  .time {
                    font-size: 10px;
                    color: #7F8A94;
                  }
                  &:hover {
                    background-color: #F2F7FF;
                  }
                }
              }
            }
            .content-personal {
              height: 100%;
              font-size: 14px;
              color: #FFFFFF;
              .default-avatar {
                position: relative;
                top: -2px;
                margin-right: 6px;
              }
            }


            .ant-dropdown-menu {
              padding: 0;
            }
          }
  
          &::after {
            .clear
          }
        }
  
        .account {
          margin-left: 90px;
          float: left;
          font-size: 14px;
          color: #fff;
        }
      }
  
      .account {
        margin-left: 90px;
        float: left;
        font-size: 14px;
        color: #fff;
  
        .ant-dropdown {
          top: 48px !important;
          width: 120px;
        }
      }
    }
  }

  .body {
    margin: auto;
    min-width: 1200px;
    max-width: 1440px;
    width: 100%;
    .tabletsDown(min-width, 100%);
    .tabletsDown(min-width, 100%);
    .tabletsDown(width, auto);
    padding: 0 20px;
    margin-top: 60px;

  }

  .footer {
    text-align: center;
    .tabletsDown(display, none);
    .copyright {
      font-size: 12px;
      color: #7F8A94;

      a {
        font-size: 12px;
        color: #7F8A94;
      }
    }
  }

  .clear {
    content: "";
    clear: both;
    display: block;
  }
}

@primary-color: #007AFF;