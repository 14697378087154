@import (reference) "./flex.less";

body {
  background-color: #f6fcff;
}

.sold-out {
  background-color: #cfcfcf;
  font-size: 12px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  color: white;
  border-radius: 4px;
}

.ant-layout {
  background-color: transparent;
}

.ant-tooltip-placement-bottom {
  z-index: 500;
}

.ant-modal {
  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-modal-footer {

    // 隐藏ant-modal footer 第一个按钮
    .ant-btn:nth-child(1) {
      display: none;
    }

    .ant-btn-default,
    .ant-btn-primary {
      padding: 4px 28px;
    }
  }

  .ant-modal-close-x {
    .anticon {
      color: #7F8A94;
      transform: translateY(-4px)
    }
  }
}

// 显示ant-modal footer 下所有按钮
.show-footer-first {
  .ant-modal {
    .ant-modal-footer {
      .ant-btn:nth-child(1) {
        display: inline-block;
      }
    }
  }
}

.ant-message {
  .ant-message-notice {
    text-align: center;
    .tabletsDown(text-align, left);
  }
}

.card-navigation {
  border-radius: 4px;
  .ant-card-head {
    padding: 0 !important;
    height: 40px;
    margin-bottom: 0;
    border-bottom: 0;
    min-height: 40px;
  }

  .ant-card-head-title {
    padding: 0;
    border-radius:3px 3px 0 0; 

    .card-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #E6ECF4;
      height: 40px;
      padding-left: 24px;

      .card-back {
        margin-top: 2px;
        margin-left: -11px;
        color: #232b3d;

        &:hover span {
          color: #007aff;
        }

        .anticon {
          color: #324558;
          position: relative;
          top: -1px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .card-title {
        font-size: 14px;
        font-weight: 400;
        color: #232B3D;
        margin-left: 6px;

        &.card-title-blod {
          font-family: HiraginoSansGB-W6, HiraginoSansGB;
          font-weight: normal;
        }
      }
    }
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-color: #939EA9;
  }
}

.ant-btn {
  border: 1px solid #e6ecf4;
  height: 36px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: none;

  span {
    color: #324558;
  }

  &:hover,
  &:focus {
    border-color: #e6ecf4;
    background-color: #f9fbff;

    span {
      color: #324558;
    }
  }

  &.btn-icon {
    padding: 0 10px;

    .anticon {
      position: relative;
      top: -3px;
    }
  }

  &.ant-btn-primary {
    padding: 0 28px;
    background-color: #0fb187;
    border-color: #0fb187;

    span {
      color: #fff;
    }

    &:hover,
    &:focus {
      background-color: #0cac83;
      border-color: #0cac83;

      span {
        color: #fff;
      }
    }

    &.btn-primary-icon {
      padding: 0 10px;

      .anticon {
        position: relative;
        top: -3px;
      }
    }
  }

  &.ant-btn-ghost {
    width: 36px;
    background-color: #333;
    border-color: #333;

    &:hover,
    &:focus {
      background-color: #3d3d3d;
      border-color: #3d3d3d;
    }
  }

  &.ant-btn-link {
    border-color: transparent;
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.btn-primary-black {
    background-color: #333;
    border-color: #333;

    span {
      color: #fff;
    }

    &:hover,
    &:focus {
      background-color: #3d3d3d;
      border-color: #3d3d3d;

      span {
        color: #fff;
      }
    }
  }

  &.btn-small {
    padding: 0 16px;
    height: 28px;
  }

  &[disabled] {
    background-color: #e6ecf4;
    border-color: #e6ecf4;

    span {
      color: #7f8a94;
    }

    &:hover,
    &:focus {
      background-color: #e6ecf4;
      border-color: #e6ecf4;

      span {
        color: #7f8a94;
      }
    }
  }

  &.ant-btn-link {
    span {
      color: #007aff;
    }
  }
}

.ant-slider-tooltip {
  padding-top: 0;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    min-height: 20px;
    font-size: 12px;
    color: #ffffff;
    height: 20px;
    padding: 0 6px;
    background-color: #007aff;
    line-height: 20px;
  }
}

.ant-table {
  .ant-table-thead>tr>th {
    padding: 10px 16px;
    height: 40px;
    background-color: #fff;
    border-color: #e6ecf4;

    &::before {
      display: none;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: 14px;
      color: #7f8a94;
      font-weight: 300;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      &:hover {

        .ant-table-cell,
        .ant-table-selection-column {
          background-color: #f2f7ff !important;
        }
      }
    }

    .ant-table-cell {
      height: 48px;
      padding: 0 16px;
    }

    .ant-table-cell,
    .ant-table-selection-column {
      color: #324558;
      border-bottom-color: #e6ecf4;

      &:hover {
        background-color: #f2f7ff !important;
      }
    }

    .ant-table-tbody>tr.ant-table-row-selected>td {
      background-color: #f2f7ff;
    }

    .ant-table-placeholder {
      .ant-table-cell {
        border-bottom-width: 0;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

.ant-form {
  &.ant-form-vertical {
    .ant-form-item-label {
      height: auto;

      label {
        height: auto;
      }
    }
  }

  .ant-form-item-no-colon {
    font-size: 14px;
    color: #324558;
    font-weight: 500;
    line-height: 40px;
  }

  // .ant-form-item-label {
  //   height: 56px;
  //   label {
  //     flex-direction: row;
  //     height: auto;
  //     font-weight: 600;
  //     align-items: baseline;
  //     &::before {
  //       margin: 0 4px 0 0;
  //     }
  //   }
  // }

  .ant-radio-button-wrapper {
    height: 40px;

    span {
      line-height: 38px;
    }
  }

  // .ant-form-item-label {
  //   padding-bottom: 16px;
  // }
}

.ant-input,
.ant-input-number {
  border-color: #e6ecf4;
  height: 40px;
  border-radius: 4px;

  &.ant-input-disabled {
    background-color: #f9fbff;
    color: #324558;
  }

  &:hover,
  &:focus {
    border-color: #e6ecf4;
  }
}

.ant-input {
  box-shadow: none;

  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
  }
}

.ant-input-group-wrapper {
  .ant-input-affix-wrapper {
    border-right-width: 0 !important;

    &:hover,
    &:focus,
    &:active {
      border-right-width: 0 !important;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0 16px;
  border-color: #e6ecf4;
  border-radius: 4px;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    border-color: #e6ecf4;
    box-shadow: none;
  }

  .ant-input-prefix {
    margin-right: 16px;
  }
}

.ant-picker {
  border-color: #e6ecf4;
  border-radius: 4px;

  &:hover,
  &.ant-picker-focus {
    border-color: #e6ecf4;
  }
}

.ant-select:not(.ant-pagination-options-size-changer) {
  .ant-select-selector {
    height: 40px !important;
    border-color: #e6ecf4 !important;
    border-radius: 4px;

    .ant-select-selection-item {
      line-height: 38px;
    }
  }
}

.ant-slider {
  .ant-slider-rail {
    margin-top: -1px;
    height: 6px;
    background-color: #e6ecf4;
  }

  .ant-slider-dot-active {
    border-color: #007aff;
  }

  .ant-slider-track {
    margin-top: -1px;
    height: 6px;
    background-color: #007aff;
  }

  .ant-slider-dot {
    display: none;
  }

  .ant-slider-mark-text {
    transform: translateX(0) !important;

    &:nth-child(2) {
      transform: translateX(-100%) !important;
    }
  }
}

.ant-pagination-prev {
  .ant-pagination-item-link {
    border-color: #e6ecf4;
    background: url("../asserts/icons/icon_left_normal_16@3x.svg") center center no-repeat;
    border-radius: 6px;

    &:hover {
      background-color: #f9fbff;
    }
  }

  &.ant-pagination-disabled {
    .ant-pagination-item-link {
      background-image: url("../asserts/icons/icon_left_not_clickable_16@3x.svg");

      &:hover {
        background-color: #fff;
      }
    }
  }

  .anticon {
    display: none;
  }
}

.ant-pagination-next {
  .ant-pagination-item-link {
    border-color: #e6ecf4;
    background: url("../asserts/icons/icon_right_normal_16@3x.svg") center center no-repeat;
    border-radius: 6px;

    &:hover {
      background-color: #f9fbff;
    }
  }

  &.ant-pagination-disabled {
    .ant-pagination-item-link {
      background-image: url("../asserts/icons/icon_right_not_clickable_16@3x.svg");

      &:hover {
        background-color: #fff;
      }
    }
  }

  .anticon {
    display: none;
  }
}

.ant-pagination-item {
  border-radius: 6px;
  border-color: #e6ecf4;

  a {
    color: #7f8a94;
  }

  &:hover,
  &:focus {
    a {
      color: #7f8a94;
    }
  }

  &.ant-pagination-item-active {
    border-color: #333;
    background-color: #333;

    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
      }
    }

    &:hover,
    &:focus {
      border-color: #333;
      background-color: #333;
    }
  }

  &:hover {
    background-color: #f9fbff;
  }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item {

  &:hover,
  &:focus {
    .ant-pagination-item-link {
      border-color: #e6ecf4;
    }

    border-color: #e6ecf4;
  }
}

.ant-pagination-options {
  margin-left: 8px;

  .ant-select {
    .ant-select-selector {
      border-radius: 6px;
      border-color: #e6ecf4;

      &:hover,
      &:focus {
        border-color: #e6ecf4;
        background-color: #f9fbff;
      }

      .ant-select-selection-item {
        color: #7f8a94;
      }
    }

    .ant-select-item-option-content {
      color: #7f8a94;
    }

    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;
        border-color: #e6ecf4 !important;
      }
    }
  }
}

.my-ant-radio-group {
  .ant-radio-button-wrapper {
    border-color: #e6ecf4;

    &::before {
      background-color: #e6ecf4;
    }
  }
}

// .ant-radio-group {
//   .ant-radio-button-wrapper {
//     border-color: #e6ecf4;
//     &::before {
//       background-color: #e6ecf4;
//     }
//   }
// }

.ant-radio {
  .ant-radio-inner {
    top: -2px;
    width: 14px;
    height: 14px;
    border-width: 4px;

    &::after {
      display: none;
    }
  }

  span {
    font-size: 14px;
    color: #324558;
  }
}

.ant-badge-count {
  height: 16px;
  padding: 1px 4px;
  border-radius: 8px;
}

.ant-layout-footer {
  background-color: #f6fcff;
}

.ant-upload-list {
  &.ant-upload-list-picture {
    .ant-upload-list-item {
      height: 40px;
      background-color: #f5f7fa;
      border-radius: 4px;
      border: none;

      .ant-upload-list-item-thumbnail {
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .ant-upload-list-item-card-actions {
        .ant-btn {
          border: none;
          background: url("../asserts/icons/icon_delete_16.svg") center center no-repeat;
          background-color: transparent;

          .anticon {
            display: none;
          }
        }
      }

      &.ant-upload-list-item-error {
        .ant-upload-list-item-thumbnail {
          height: 54px;
        }
      }
    }
  }
}

.page-header {
  padding: 24px 0;
  padding-bottom: 8px;

  .caption {
    margin-bottom: 8px;
    font-size: 16px;
    color: #324558;
    line-height: 16px;
  }

  .description {
    font-size: 12px;
    color: #7f8a94;
    line-height: 18px;
    margin-bottom: 0;
  }
}

.annotation-icon {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url('../asserts/icons/icon_annotation_normal_16.svg') center center / 100% 100% no-repeat;

  &:hover {
    background-image: url('../asserts/icons/icon_annotation_highlight_16.svg');
  }
}

// Dropdown
.ant-dropdown-menu-title-content {
  white-space: nowrap;
}

// switch
.ant-switch-checked {
  background: #0FB187;
}

.ant-table-column-sorters {
  justify-content: flex-start;

  .ant-table-column-title {
    flex: initial;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 20px;
  }
}

.instances {
  border-radius: 4px;
}