.btn-refresh {
    background-color: #333;
    border-radius: 4px;
    &:hover,
    &:focus {
      background-color: #333;
    }
    .anticon {
      position: relative;
    }
  }
@primary-color: #007AFF;