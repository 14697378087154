.bill-container {
    .show-bills{
        display: none;
    }
    .bill-history {
        margin-top: 20px;
    }
    .outstanding-box{
        &:not(:first-of-type){
            margin-top: 10px;
        }
    }

}
@primary-color: #007AFF;