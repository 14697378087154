@import (reference) "../../../styles/flex.less";

.instances-container {
  width: 1200px;
  .tabletsDown(width, 100%);
  margin: auto;

  .instances {
    padding: 20px;
    background-color: #fff;

    .btn-create {
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: #0FB187;
      border-radius: 4px;

      .anticon {
        position: relative;
        top: -3px;
      }
    }

    .table {
      margin-top: 16px;

      .table-label-status {
        display: flex;
        align-items: center;
        vertical-align: middle;

        span:nth-of-type(1) {
          padding-right: 3px;
        }
      }

      .error-label {
        color: #D0021B;

        .anticon {
          color: #aab1b9;
        }
      }

      .column-operation {
        text-align: center;
      }

      .annotation-icon {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('../../../asserts/icons/icon_annotation_normal_16.svg') center center / 100% 100% no-repeat;

        &:hover {
          background-image: url('../../../asserts/icons/icon_annotation_highlight_16.svg');
        }
      }
    }
  }
}
.tooltip-setconfig {
  span {
    cursor: pointer;
    text-decoration:underline;
  }
}
@primary-color: #007AFF;