.payment-success-modal {
    .payment-modal {
        margin-top: 40px;
        text-align: center;

        img {
            width: 80px;
            height: 80px;
        }

        p {
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #324558;
            margin-top: 24px;
        }

        .payment-modal-btn {
            padding: 64px;
            padding-top: 0;
            margin-top: 80px;
            display: flex;
            justify-content: space-around;
            align-items: center;

        }
    }
}
@primary-color: #007AFF;