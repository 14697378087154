.ticket-container {
  width: 1200px;
  margin: auto;
  .card-header {
    .tools {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .billing-cycle {
        margin-right: 8px;
      }
      .close-btn {
        width: 116px;
        color: #324558;
      }
    }
  }
  .ant-card-body {
    padding: 0;
  }
  .ticket-chat {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 240px;
    width: 880px;
    min-height: 500px;
    border-right: 1px solid #E6ECF4;
    .replies {
      padding: 40px 64px;
      .item {
        .info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .admin-head {
            margin-right: 16px;
            width: 40px;
            height: 40px;
            background-color: #F5F7FA;
            border-radius: 50%;
            img {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .username {
            margin-right: 8px;
          }
          .username,
          .reply-time {
            font-size: 14px;
            color: #7F8A94;
          }
        }
        .content {
          position: relative;
          max-width: 500px;
          display: inline-block;
          margin: 12px 56px 0;
          padding: 12px 24px;
          font-size: 14px;
          color: #324558;
          background-color: #F5F7FA;
          border-radius: 10px;
          text-align: left;
          &::after {
            content: "";
            display: block;
            position: absolute;
            border-bottom: 16px solid #F5F7FA;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
          }
          p {
            word-wrap: break-word;
          }
        }

        &.resolver-item {
          .content {
            &::after {
              top: -9px;
              left: -7px;
              transform: rotate(-45deg);
            }
          }
        }

        &.user-item {
          text-align: right;
          .info {
            justify-content: flex-end;
            .reply-time {
              margin-right: 16px;
            }
          }
          .content {
            &::after {
              top: -9px;
              right: -7px;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .reply-form {
      border-top: 1px solid #E6ECF4;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 24px;
    }
  }
  .ticket-info {
    display: inline-block;
    vertical-align: top;
    width: 318px;
    padding: 24px;
    .title {
      margin-bottom: 24px;
      font-size: 16px;
      color: #324558;
      font-weight: 600;
    }
    .label {
      margin-bottom: 8px;
      font-size: 14px;
      color: #7F8A94;
    }
    .value {
      margin-bottom: 24px;
      font-size: 14px;
      color: #324558;
    }
  }
}

@primary-color: #007AFF;