.proticol-box {
    table {
        tr {
            border-bottom: 1px solid #E5E5E5;

            td,
            th {
                padding: 10px;
                text-align: left;

                &:last-of-type {
                    border-left: 1px solid #E5E5E5;
                }
            }

            th {
                min-width: 270px;
            }
        }

        thead {
            tr {
                background: #F2F2F2;
            }
        }

        tbody {
            background: #fff;
        }
    }
}
@primary-color: #007AFF;