.payment-type-modal {
    .ant-modal-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #324558;
    }

    .ant-modal-body {
        padding: 20px
    }

    .public-pay {
        padding-bottom: 25px;
        padding-left: 25px;

        p {
            min-height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #324558;
            line-height: 20px;

            &.payment-strong {
                line-height: 24px;
            }

            &.payment-explain {
                color: #7F8A94;
                line-height: 24px;
            }
        }

        .public-table {
            margin: 8px 0;

            tr {
                border-bottom: 1px solid #E6ECF4;
                border-right: 1px solid #E6ECF4;

                td {
                    border-left: 1px solid #E6ECF4;
                    border-top: 1px solid #E6ECF4;
                    padding: 4px 10px;
                    height: 20px;
                    min-width: 300px;
                    line-height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #324558;

                    &:first-child {
                        background: #F2F7FF;
                        min-width: 120px;
                    }
                }
            }
        }
    }

    .qrcode-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .qrcode-qr {
            width: 300px;
            height: 300px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #E6ECF4;
            position: relative;

            .url-error {
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(246, 252, 255, .9);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                p {
                    margin-top: 8px;
                }
            }
        }

        >span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 300px;
            height: 44px;
            background: #74D2D4;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 8px;
            margin-bottom: 24px;
        }
    }

    .payment-price {
        margin-top: 8px;
        margin-bottom: 28px;

        b {
            font-size: 16px;
            font-family: HiraginoSansGB-W6, HiraginoSansGB;
            font-weight: normal;
            color: #D0021B;
        }
    }
}
@primary-color: #007AFF;