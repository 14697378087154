.sass-rule {
    p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #324558;
        line-height: 24px;

        span {
            font-weight: 600;
        }
    }

    .sass-br {
        height: 20px;
    }
}

.sass-mobile {
    width: 100%;
    background: #fff;
    height: calc(100vh - 64px);
    padding: 14px;
    border-top: 1px solid #E6ECF4;
    overflow-x: hidden;
    position: relative;
    b{
        display: inline-block;
        margin: 10px 0;
    }
    .sass-rule {
        .sass-br {
            height: 10px;
        }
    }

    .know-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #E6ECF4;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 14px 0;
        padding-bottom: 24px;
    }

    .know {
        display: inline-block;
        width: 90%;
        height: 40px;
        line-height: 40px;
        background: #0FB187;
        text-align: center;
        color: #fff;
        border-radius: 12px;
    }
}
@primary-color: #007AFF;