// 账单公共样式
.info-title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .info-title-item {
        width: 33.3%;
        display: flex;
        align-items: center;
        font-size: 14px;

        span {
            font-family: PingFangSC-Regular, PingFang SC;
            min-width: 70px;
            display: inline-block;
            font-weight: 400;
            color: #7F8A94;
        }

        .width98 {
            min-width: 98px;
        }

        b {
            font-family: PingFangSC-Regular, PingFang SC;
            color: #324558;
            font-weight: 400;

            &.touch-link {
                color: #007AFF;
                cursor: pointer;
            }
        }
    }
}

// 账单底部公共样式
.footer-pay {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-pay-price {
        span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7F8A94;
            padding-right: 45px;

            b {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #324558;
            }
        }
    }

    .receipt-button {
        width: 84px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #324558;
    }
}

// 订单盒子
.box {
    &::before {
        content: '';
        width: 6px;
        height: calc(100% + 2px);
        background-color: #333;
        position: absolute;
        top: -1px;
        left: 0;
    }

    position: relative;
    min-height: 50px;
    padding: 24px;
    border: 1px solid #E6ECF4;
    border-left: 0;
    display: flex;
    flex-wrap: wrap;
}

// 账单 单独设置
.order-title {
    .info-title-item {
        width: 50%;

        &:nth-of-type(3),
        &:nth-of-type(4) {
            margin: 8px 0;
        }
    }
}

// 账单详情 单独设置
.bill-title {
    flex-wrap: wrap-reverse;
    padding-bottom: 24px;
    .info-title-item {

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            margin-bottom: 8px;
        }

        &:nth-of-type(3) {
            span {
                min-width: 42px;
            }
        }
    }
}

//付款
.pay-title {
    padding-bottom: 16px;
    margin-top: -4px;

    .info-title-item {
        span {
            min-width: 42px;
        }
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            margin-bottom: 8px;
        }
    }
}

.pay-box-title {
    &:nth-of-type(n+3) {
        margin-top: 16px;
    }

    .info-title-item {
        span {
            min-width: 56px;
        }

        &:last-of-type {
            margin-top: 8px;
        }
    }
}
@primary-color: #007AFF;