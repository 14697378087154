.modafiy-quicken {
    .modafiy-quicken-box {
        padding-bottom: 24px;
        font-family: PingFangSC-Medium, PingFang SC;

        .quicken-title {
            height: 40px;
            background: #E6ECF4;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #324558;
            padding-left: 10px;

            &:first-of-type {
                margin-bottom: 8px;
            }

            &:nth-last-of-type(1) {
                margin-top: 24px;
            }
        }

        p {
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 14px;
            font-weight: 400;
            color: #324558;
            line-height: 20px;
        }

        span {
            display: block;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #324558;
            line-height: 20px;
            padding: 8px 0;
        }
    }
}
@primary-color: #007AFF;