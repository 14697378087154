@import (reference) "../../styles/flex.less";

.login-layout {
  min-height: 100vh;
  min-width: 1200px;
  .tabletsDown(min-width, 100%);

  .pc-layout {
    .tabletsDown(display, none);
    background: #ACCDFF;
    header {
      padding: 0 24px;
      width: 100%;
      height: 60px;
      background-color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      nav {
        margin-left: 40px;

        a {
          font-size: 16px;
          color: #324558;
        }
      }
    }

    .login-layout-bg {
      width: 100%;
      height: calc(100vh - 60px);
      min-height: 416px;
      background-color: #ACCDFF;
      background: url('../../asserts/login/img_yun@2x.png') center center / 1920px 1020px no-repeat;

      .login-layout-container {
        position: relative;
        width: 1200px;
        margin: auto;
        height: 100%;

        .login-layout-ad {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          .ad-aws {
            font-size: 42px;
            color: #324558;
            line-height: 40px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN, Arial;
            font-weight: 700;
            span {
              margin: 0 8px;
              font-size: 68px;
              color: #FF8A5C;
              font-weight: 900;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN, Arial;
            }
          }

          .ad-discount {
            position: relative;

            p {
              height: 42px;
              display: inline-block;
              font-size: 42px;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN, Arial;
              font-weight: 700;
              color: #324558;

              span {
                position: relative;
                top: 5px;
                font-size: 68px;
                padding: 0 16px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN, Arial;
                font-weight: 900;
                color: #FF8A5C;
              }

              // &::after {
              //   content: "";
              //   display: inline-block;
              //   width: 120px;
              //   height: 124px;
              //   transform: scale(.8);
              //   background: url('../../asserts/login/img_yun_1@2x.png') center center / 100% 100% no-repeat;
              //   vertical-align: top;
              // }
            }

            .ad-voucher {
              display: block;
              margin-top: 14px;
              font-size: 14px;
              font-family: SourceHanSansCN-Normal, SourceHanSansCN;
              font-weight: 400;
              color: #007AFF;
              line-height: 21px;
              cursor: pointer;
            }
          }

          .ad-features {
            margin-top: 38px;

            li {
              margin-bottom: 24px;
              height: 20px;
              font-size: 20px;
              font-family: SourceHanSansCN-Normal, SourceHanSansCN,Arial;
              font-weight: 400;
              color: #324558;
              line-height: 24px;

              &::before {
                content: "";
                display: inline-block;
                margin-right: 8px;
                width: 24px;
                height: 24px;
                background: url('../../asserts/login/icon_pod_24.svg') center center / 100% 100% no-repeat;
                vertical-align: top;
              }
            }
          }
        }

        .login-box {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 480px;
          background-color: rgba(255, 255, 255, 0.96);
          border-radius: 10px;
        }
      }
    }

    .ant-modal .ant-modal-body {
      padding-bottom: 20px;
    }
  }

  .mobile-layout {
    display: none;
    .tabletsDown(display, block);

    header {
      height: 64px;
      background-color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .logo {
        margin-left: 12px;
        width: 212px;
        height: 32px;
      }

      nav {
        margin-left: 20px;

        a {
          font-size: 15px;
          color: #324558;
        }
      }
    }
  }
}
@primary-color: #007AFF;