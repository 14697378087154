@import (reference) "../../../styles/flex.less";

.sign-up-success-container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 60px;
    min-width: 1200px;
    background-color: #fff;
  }
  .success-box {
    margin: 60px auto;
    padding: 64px 0;
    width: 440px;
    height: 100%;
    background-color: #fff;
    text-align: center;
    .tabletsDown(margin-top, 0);
    .tabletsDown(width, 100%);
    .success-icon,
    .activate-icon {
      margin: 0 auto 32px;
      width: 80px;
      height: 80px;
      background: url("../../../asserts/icons/icon_successful_80.svg") center
        center no-repeat;
    }
    .activate-icon {
      background-image: url("../../../asserts/icons/icon_activation_80.svg");
    }
    .success-content {
      margin-bottom: 12px;
      font-size: 16px;
      color: #324558;
    }
    .activate-content {
      margin-bottom: 64px;
      font-size: 14;
      color: #324558;
    }
    .success-btn {
      width: 320px;
    }
  }
  .btn-gologin-mobile {
    display: none;
      margin: 30px auto;
      width: 320px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #0FB187;
      font-size: 16px;
      color: #0FB187;
      line-height: 34px;
      text-align: center;
      .tabletsDown(display, block);
  }
}


@primary-color: #007AFF;