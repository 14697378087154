.ticket-submit-container {
  width: 1200px;
  margin: auto;

  .ant-form.ant-form-vertical {
    .ant-form-item-label label {
      height: 14px;
    }
  }

  .form {
    padding: 30px 0;
    margin: auto;
    width: 600px;
  }

  .ant-upload-list {
    margin-top: 30px;
  }

  .upload-tip {
    margin-top: 67px;
    margin-bottom: -66px;
    font-size: 14px;
    color: #7F8A94;
    line-height: 24px;
  }

  .form-footer {
    text-align: right;
    padding: 24px 24px;
    padding-bottom: 0;
    border-top: 1px solid #E6ECF4;
  }
}
@primary-color: #007AFF;