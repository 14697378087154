.doc-container {
    padding: 40px 0;
    min-height: 100vh;
    max-width: 1000px;
    margin: auto;
    h1 {
        font-size: 18px;
        color: #000;
        font-weight: 600;

        text-align: center;
    }
    h2 {
        // text-indent: 2em;
        font-size: 14px;
        color: #000;
        text-align: left;
        font-weight: 600;

    }
    p {
        text-indent: 2em;
        text-align: left;
        font-size: 14px;
        color: #000;
        strong {
            font-size: 14px;
        }
    }
    div {
        span{
            display: block;
            padding-left: 30px;
        }
    }
}

@primary-color: #007AFF;