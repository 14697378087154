.create-instance-container {
  margin: auto;
  width: 1200px;

  .ant-form.ant-form-vertical {
    .ant-form-item-label label {
      height: 14px;
    }
  }

  .ant-card-head {
    padding: 0 16px;
  }

  .form {
    // padding: 30px 0;
    padding-bottom: 28px;
    width: 600px;
    margin: auto;
  }

  .price {
    height: 40px;
    background: rgba(0, 122, 255, 0.08);
    border-radius: 4px;
    border: 1px solid #E6ECF4;
    padding: 10px 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #324558;
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    >div {
      width: 50%;

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
    }

  }

  .quicken {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 0px;
    padding-top: 13px;
    border-top: 1px dashed #E6ECF4;

    .ant-form-item-label {
      padding-bottom: 0;
      padding-right: 16px;
    }
  }

  .quicken-info {
    color: #7F8A94;
    margin-bottom: 28px;

    span {
      color: #007AFF;
      cursor: pointer;
    }
  }

  .free-warning {
    color: #324558;
    margin-bottom: 24px;
    font-size: 14px;
    span {
      color: #ff4d4f;
    }
  }

  .form-footer {
    padding-top: 28px;
    border-top: 1px solid #e6ecf4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .btn-submit {
      width: 112px;
      height: 36px;
    }
  }

  .server-region {
    .ant-select-disabled {
      .ant-select-selector {
        background-color: #F9FBFF;
        .ant-select-selection-item {
          color: #7F8A94;
        }
      }
    }
  }
}
@primary-color: #007AFF;