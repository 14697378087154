@import (reference) "../../../styles/flex.less";

.sign-in-container {
  padding-top: 60px;
  .tabletsDown(display, none);

  .title {
    margin-bottom: 32px;
    padding: 0 40px;
    color: #324558;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }

  .sign-in-form {
    padding: 0 40px;
  }

  .verify-box {
    background-color: #fff;
    height: 40px;
    text-align: center;

    img {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }

  .go-activate {
    padding: 0 16px;
    width: 400px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(0, 122, 255, 0.08);
    border-radius: 4px;

    .activate-icon {
      color: #007aff;
      margin-right: 16px;
    }
  }

  .form-submit {
    margin-top: 12px;
    background-color: #0FB187;
    border-color: #0FB187;
    height: 36px;
    padding: 0 42px;

    &:hover,
    &:active,
    &:focus {
      background-color: #0FB187;
      border-color: #0FB187;
    }
  }

  .sign-in-tools {
    margin-top: 60px;
    height: 68px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed #e6ecf4;
  }
}

.sign-in-container-mobile {
  display: none;
  .tabletsDown(display, block);
  padding: 54px 30px;
  min-height: calc(100vh - 64px);
  background-color: #46acf4;
  background: url("../../../asserts/login/img_logo_black.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  .ad-aws {
    display: inline-block;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #324558;

    span {
      position: relative;
      top: 3px;
      font-size: 30px;
      padding: 0 4px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: 900;
      color: #FF8A5C;
    }
  }

  .ad-discount {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      display: inline-block;
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #324558;

      span {
        position: relative;
        top: 3px;
        font-size: 30px;
        padding: 0 8px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: 900;
        color: #FF8A5C;
      }
    }

    .ad-voucher {
      display: block;
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #007AFF;
      cursor: pointer;
    }
  }

  .form-submit-mobile {
    margin-top: 12px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    height: 40px;
    width: 100%;
    padding: 0 42px;
    border-radius: 6px;

    span {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0FB187;
      font-size: 14px;

    }

    &:hover,
    &:active,
    &:focus {
      background-color: #FFFFFF;
      border-color: #FFFFFF;

      span {
        color: #0FB187;
      }
    }
  }

  .reset-box {
    margin-top: 14px;
    margin-bottom: 30px;
    line-height: 12px;
    text-align: right;

    .reset-text {
      font-size: 14px;
      color: #fff;
      line-height: 12px;
    }
  }

  .register-btn-mobile {
    display: block;
    height: 40px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ffffff;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
  }

  .go-activate {
    height: 28px;
    line-height: 28px;
    background-color: rgba(0, 122, 255, 0.08);
    border-radius: 4px;
    color: #fff;

    .activate-icon {
      color: #fff;
      margin-right: 8px;
    }

    a {
      color: #ff8a5c;
    }
  }

  .sign-in-form {
    .ant-input-affix-wrapper {
      background: rgba(255, 255, 255, 0.2);
      border-color: transparent;
    }

    .ant-input-affix-wrapper-status-error {
      background: transparent !important;
    }

    .ant-input {
      background: transparent;
      color: #fff;

      &::placeholder {
        color: #fff !important;
      }
    }
  }
}
@primary-color: #007AFF;