.address-container {
    .address-table {
        margin-top: 18px;
    }

    .empty-color {
        color: #7F8A94;
    }

    .address_config {
        .ant-btn {
            padding-left: 0;
        }
    }

    .ant-table-tbody {
        tr {

            .default-btn,
            .set-default-btn {
                display: inline-block;
                width: 64px;
                height: 28px;
                line-height: 28px;
                border-radius: 4px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: center;
            }

            .default-btn {
                background: rgba(0, 122, 255, 0.08);
                color: #007AFF;
                cursor: no-drop;
            }

            .set-default-btn {
                border: 1px solid #324558;
                line-height: 26px;
                color: #324558;
                cursor: pointer;
                // overflow: hidden;
                // width: 0px;
                // height: 0px;
                opacity: 0;
                transition: opacity .5s linear;
            }

            &:hover {
                .set-default-btn {
                    // width: 64px;
                    // height: 28px;
                    opacity: 1;
                }
            }
        }
    }
}
@primary-color: #007AFF;